<template>
  <div style="font-family: 'Hiragino Sans'">
    <p class="brand">{{ TShirt.brandName }}</p>
    <h1 class="product-name">{{ TShirt.name }}</h1>
    <div class="slide">
      <Carousel
      :slides-path="TShirt.slidesPath"
      :class="{'full-screen': isFullScreen}"/>
    </div>
    <h2 class="title">{{ TShirt.title }}</h2>
    <p class="description">{{ TShirt.description }}</p>
    <div class="table-container">
      <div class="table">
        <div class="table-item">
          <p class="table-item-header">サイズ</p>
          <p class="table-item-main">{{ TShirt.size }}</p>
        </div>
        <div class="table-item">
        <div class="table-item-header">素材</div>
        <div class="table-item-main">{{ TShirt.material }}</div>
        </div>
        <div class="table-item">
        <div class="table-item-header">値段</div>
        <div class="table-item-main">{{ TShirt.price }}</div>
        </div>
      </div>
    </div>
    <a :href="TShirt.storeLink"
      class="store">
      {{ TShirt.storeName }}で詳細を見る
    </a>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue';

export default {
  components: {
    Carousel,
  },
  data() {
    return {
      isFullScreen: false,
    };
  },
  computed: {
    TShirt() {
      const TShirt = this.$store.state.Tshirts
        .find((Tshirt) => Tshirt.id === this.$store.state.selectedTshirtId);
      if (TShirt === undefined) return this.$store.state.Tshirts[0];
      return TShirt;
    },
  },
  created() {
    this.$store.state.selectedTshirtId = Number(this.$route.params.id);
  },
  mounted() {
    const mql = window.matchMedia('(max-width: 600px)');
    this.isFullScreen = mql.matches;
    mql.addEventListener('change', (event) => {
      this.isFullScreen = event.matches;
    });
  },
};
</script>

<style scoped>
.brand{
  font-family: 'Hiragino Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #9BA0AE;
}
.product-name{
  font-family: 'Hiragino Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #191A1D;
  margin-top: 4px;
}
.slide{
  margin-top: 32px;
}
.full-screen {
  margin: 0 -24px;
}
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #191A1D;
  margin-top: 32px;
}
.description {
  font-size: 15px;
  line-height: 24px;
  color: #191A1D;
  margin-top: 16px;
}
.table-container {
  margin-top: 24px;
  border-top: 1px solid #EDF0F8;
  border-bottom: 1px solid #EDF0F8;
}
.table {
  padding: 0 24px;
}
.table-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 0;
  border-bottom: 1px solid #EDF0F8;
}
.table-item-header {
  color: #9BA0AE;
  min-width: 32px;
  margin-right: 16px;
}
.table-item-main {
  color: #191A1D;
}
.store{
  display:block;
  text-decoration: none;
  margin: 24px auto;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #191A1D;
  padding: 10px 0px;
  border: 1px solid #191A1D;
  border-radius: 6px;
  max-width: 335px;
  text-align: center;
}
</style>
