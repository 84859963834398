<template>
    <div class="desktop">
      <aside class="sidebar">
        <Header />
        <List style="margin-top: 64px;"></List>
        <Footer />
      </aside>
      <main>
        <Detail></Detail>
      </main>
  </div>
</template>

<script>
import List from '@/components/List.vue';
import Detail from '@/components/Detail.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    List,
    Detail,
    Header,
    Footer,
  },
};
</script>

<style scoped>
.desktop {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.sidebar {
  height: calc(100vh - 48px);
  padding-left: 32px;
  width: 365px;
  margin-right: 64px;
  align-self: flex-start;
  position:sticky;
  top: 64px;
}
main {
  width: 700px;
}
</style>
