<template>
  <div :class="['list-item', {selected: isSelectedList}]" @click="showDetail">
    <div class="brand">
      <img :src="require(`@/assets/${brandLogo}`)" alt="brand-logo" width="20" height="20">
      <span class="brand-name">{{ brandName }}</span>
    </div>
    <p class="product-name">{{ productName }}</p>
  </div>
</template>

<script>
export default {
  props: {
    tshirtId: {
      type: Number,
      required: true,
    },
    brandLogo: {
      type: String,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelectedList() {
      return String(this.tshirtId) === this.$route.params.id;
    },
  },
  methods: {
    showDetail() {
      if (!this.isSelectedList) {
        this.$store.state.selectedTshirtId = this.tshirtId;
        this.$router.push(`/${this.tshirtId}`);
      }
    },
  },
};
</script>

<style scoped>
.list-item {
  padding: 16px 0;
  cursor: pointer;
}
.selected {
  background-color: #F2F2F2;
}
.list-item:hover {
  background-color: #EDF0F8
}
.brand {
  display: flex;
  align-items: center;
}
img {
    margin-right: 16px;
}
.brand-name {
  font-family: 'Hiragino Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #9BA0AE
}
.product-name {
  margin: 4px 0 0 36px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #191A1D;
}

</style>
