<template>
  <div class="smart-phone">
    <transition name="fade" mode="out-in" @after-leave="key++">
      <keep-alive>
        <SmartPhoneDetail :key="key" v-if="isShowDetail" />
        <SmartPhoneList v-else />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import SmartPhoneDetail from '@/components/SmartPhoneDetail.vue';
import SmartPhoneList from '@/components/SmartPhoneList.vue';

export default {
  components: {
    SmartPhoneDetail,
    SmartPhoneList,
  },
  data() {
    return {
      isShowDetail: false,
      key: 0,
    };
  },
  watch: {
    $route: {
      handler(newRoute) {
        if (newRoute.params.id === undefined) {
          this.isShowDetail = false;
        } else {
          this.isShowDetail = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.smart-phone {
  max-width: 600px;
  margin: 0 auto;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
