import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedTshirtId: 0,
    Tshirts: [
      {
        id: 0,
        brandName: 'Hanes',
        brandLogo: 'Hanes/logo.webp',
        name: 'ビーフィー Tシャツ BEEFY-T',
        slidesPath: 'Hanes',
        title: 'ミニマリストの定番白T',
        description: '1901年にアメリカで創業されたTシャツメーカーHanesが作り出した、肉厚Tシャツ。今ではミニマリストの定番アイテムとなっています。',
        size: 'XS, S, M, L, XL',
        material: '100% 綿',
        price: '￥1,595',
        storeLink: 'https://www.amazon.co.jp/dp/B00RF33R6E/ref=cm_sw_em_r_mt_dp_GP0KGMRCKG0685H9S38P?_encoding=UTF8&psc=1',
        storeName: 'Amazon',
      }, {
        id: 1,
        brandName: 'UNIQLO',
        brandLogo: 'UNIQLO/logo.svg',
        name: 'エアリズムコットンオーバーサイズTシャツ（5分袖）',
        slidesPath: 'UNIQLO',
        title: '究極のハイブリットTシャツ',
        description: '表地はコットン、裏地はユニクロ独自の素材であるエアリズムを使用し、着心地の良さと、１枚で着て行けるデザインを合わせ持ったTシャツです。シワになりにくく、生地の艶の高級感をが特徴です。また５部丈なので、秋頃まで活躍してくれる、万能Tシャツになります。',
        size: 'XS, S, M, L, XL, XXL, 3XL',
        material: '53% 綿,30% ポリエステル,17% 複合繊維 （ポリエステル）',
        price: '￥1,500',
        storeLink: 'https://www.uniqlo.com/jp/ja/products/E425974-000/00?colorDisplayCode=00&sizeDisplayCode=004',
        storeName: 'UNIQLO',
      }, {
        id: 2,
        brandName: '無印良品',
        brandLogo: 'muji/logo.webp',
        name: 'インド綿　天竺編みクルーネック半袖Ｔシャツ',
        slidesPath: 'muji',
        title: 'コスパ最強Tシャツ',
        description: '1000円以下で購入できる上に、他のTシャツと遜色無い性能ですので、コストパフォーマンスに優れたTシャツと言えます。白Tは汚れやすいから安く買いたいと言う方にオススメです！',
        size: 'XS, S, M, L, XL, XXL',
        material: '綿100%',
        price: '￥990',
        storeLink: 'https://www.muji.com/jp/ja/store/cmdty/detail/4550182065770',
        storeName: '無印良品',
      }],
  },
});
