<template>
  <div
    key="list"
    style="padding: 32px;">
      <Header />
      <List style="margin-top: 32px;"></List>
      <Footer />
    </div>
</template>

<script>
import List from '@/components/List.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    List,
    Header,
    Footer,
  },
};
</script>
