<template>
  <div>
    <ListItem
    v-for="(TShirt, index) in TShirtsData"
    :key="TShirt.id"
    :class="{
      'border-bottom': true,
      'border-top': index === 0}"
    :tshirt-id="TShirt.id"
    :brand-logo="TShirt.brandLogo"
    :brand-name="TShirt.brandName"
    :product-name="TShirt.name" />
    </div>
</template>

<script>
import ListItem from './ListItem.vue';

export default {
  components: {
    ListItem,
  },
  computed: {
    TShirtsData() {
      return this.$store.state.Tshirts;
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #edf0f8;
}
.border-top {
  border-top: 1px solid #edf0f8;
}
</style>
