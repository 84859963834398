<template>
  <router-view />
</template>

<script>
export default {
};
</script>
<style>
body, p, h1, h2 {
  margin: 0;
}
</style>
