<template>
  <div
    key="detail"
    style="padding: 0 24px;">
    <div
    style="height:56px; display:flex; align-items: center; cursor: pointer;"
    @click="$router.push('/')">
      <img src="@/assets/logo-yoko.svg" width=38 height=20 alt="logo">
    </div>
    <Detail style="margin-top: 16px;" />
  </div>
</template>

<script>
import Detail from '@/components/Detail.vue';

export default {
  components: {
    Detail,
  },
};
</script>
