<template>
  <div>
    <Desktop v-if="isDesktop" />
    <SmartPhone v-else/>
  </div>
</template>

<script>
import Desktop from '@/components/Desktop.vue';
import SmartPhone from '@/components/SmartPhone.vue';

export default {
  components: {
    Desktop,
    SmartPhone,
  },
  data() {
    return {
      isDesktop: true,
    };
  },
  mounted() {
    const mql = window.matchMedia('(min-width: 1185px)');
    this.isDesktop = mql.matches;
    mql.addEventListener('change', (event) => {
      this.isDesktop = event.matches;
    });
  },
};
</script>
