<template>
  <div
    ref="slider"
    class="keen-slider"
  >
    <div style="position: absolute; bottom: 26px; right: 32px; z-index: 5;">
      <div v-if="isShowArrow"
        style="display: flex; justify-content: space-between; margin-bottom: 8px;">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          :style="{ cursor: isDisablePrev? 'default': 'pointer',
                    fill: isDisablePrev ? '#9BA0AE': '#191A1D'}"
          @click="slider.prev()"
        ><path
          d="M0 0h24v24H0V0z"
          fill="none"
        /><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" /></svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          :style="{ cursor: isDisableNext? 'default': 'pointer',
                    fill: isDisableNext ? '#9BA0AE': '#191A1D'}"
          @click="slider.next()"
        ><path
          d="M0 0h24v24H0V0z"
          fill="none"
        /><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" /></svg>
      </div>
      <div style="background-color: #EDF0F8; height: 2px; width: 80px;">
        <div
          :style="{'background-color': '#191A1D',
                   'height': '2px',
                   'width': `${progress}%`,
                   'transition': 'width 0.1s linear'
          }"
        />
      </div>
    </div>
    <div v-for="slidesImageName in slidesImageNames"
      :key=slidesImageName class="keen-slider__slide">
      <img
        style="width: 100%;"
        :src="require(`@/assets/${slidesPath}/${slidesImageName}`)"
      >
    </div>
  </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';

export default {
  props: {
    slidesPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
      isDisableNext: false,
      isDisablePrev: true,
      slidesImageNames: ['front.webp', 'side.webp', 'back.webp', 'chest.webp', 'material.webp', 'thickness.webp', 'wrinkles.webp', 'stain.webp'],
      isShowArrow: false,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.slider = new KeenSlider(this.$refs.slider, {
      slideChanged: (slider) => {
        const currentSlideIndex = slider.details().relativeSlide;
        const lastSlideIndex = slider.details().size - 1;
        this.progress = (currentSlideIndex / lastSlideIndex) * 100;
        if (currentSlideIndex === 0) {
          this.isDisableNext = false;
          this.isDisablePrev = true;
        } else if (currentSlideIndex === lastSlideIndex) {
          this.isDisableNext = true;
          this.isDisablePrev = false;
        } else {
          this.isDisableNext = false;
          this.isDisablePrev = false;
        }
      },
    });
    const mql = window.matchMedia('(max-width: 600px)');
    this.isShowArrow = !mql.matches;
    mql.addEventListener('change', (event) => {
      this.isShowArrow = !event.matches;
    });
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
};
</script>
